import arClients from './locales/ar/clients.json';
import arComponents from './locales/ar/components.json';
import arDocbuilder from './locales/ar/docbuilder.json';
import arSessions from './locales/ar/sessions.json';
import daClients from './locales/da/clients.json';
import daComponents from './locales/da/components.json';
import daDocbuilder from './locales/da/docbuilder.json';
import daSessions from './locales/da/sessions.json';
import deClients from './locales/de/clients.json';
import deComponents from './locales/de/components.json';
import deDocbuilder from './locales/de/docbuilder.json';
import deSessions from './locales/de/sessions.json';
import enAuClients from './locales/en-AU/clients.json';
import enAuComponents from './locales/en-AU/components.json';
import enAuDocbuilder from './locales/en-AU/docbuilder.json';
import enAuSessions from './locales/en-AU/sessions.json';
import enGbClients from './locales/en-GB/clients.json';
import enGbComponents from './locales/en-GB/components.json';
import enGbDocbuilder from './locales/en-GB/docbuilder.json';
import enGbSessions from './locales/en-GB/sessions.json';
import enUsClients from './locales/en-US/clients.json';
import enUsComponents from './locales/en-US/components.json';
import enUsDocbuilder from './locales/en-US/docbuilder.json';
import enUsSessions from './locales/en-US/sessions.json';
import esClients from './locales/es/clients.json';
import esComponents from './locales/es/components.json';
import esDocbuilder from './locales/es/docbuilder.json';
import esSessions from './locales/es/sessions.json';
import frClients from './locales/fr/clients.json';
import frComponents from './locales/fr/components.json';
import frDocbuilder from './locales/fr/docbuilder.json';
import frSessions from './locales/fr/sessions.json';
import hiClients from './locales/hi/clients.json';
import hiComponents from './locales/hi/components.json';
import hiDocbuilder from './locales/hi/docbuilder.json';
import hiSessions from './locales/hi/sessions.json';
import itClients from './locales/it/clients.json';
import itComponents from './locales/it/components.json';
import itDocbuilder from './locales/it/docbuilder.json';
import itSessions from './locales/it/sessions.json';
import jaClients from './locales/ja/clients.json';
import jaComponents from './locales/ja/components.json';
import jaDocbuilder from './locales/ja/docbuilder.json';
import jaSessions from './locales/ja/sessions.json';
import koClients from './locales/ko/clients.json';
import koComponents from './locales/ko/components.json';
import koDocbuilder from './locales/ko/docbuilder.json';
import koSessions from './locales/ko/sessions.json';
import ptClients from './locales/pt/clients.json';
import ptComponents from './locales/pt/components.json';
import ptDocbuilder from './locales/pt/docbuilder.json';
import ptSessions from './locales/pt/sessions.json';
import trClients from './locales/tr/clients.json';
import trComponents from './locales/tr/components.json';
import trDocbuilder from './locales/tr/docbuilder.json';
import trSessions from './locales/tr/sessions.json';
import zhCnClients from './locales/zh-CN/clients.json';
import zhCnComponents from './locales/zh-CN/components.json';
import zhCnDocbuilder from './locales/zh-CN/docbuilder.json';
import zhCnSessions from './locales/zh-CN/sessions.json';
import zhHkClients from './locales/zh-HK/clients.json';
import zhHkComponents from './locales/zh-HK/components.json';
import zhHkDocbuilder from './locales/zh-HK/docbuilder.json';
import zhHkSessions from './locales/zh-HK/sessions.json';

export const resources = {
  'en-AU': { sessions: enAuSessions, clients: enAuClients, components: enAuComponents, docbuilder: enAuDocbuilder },
  'en-GB': { sessions: enGbSessions, clients: enGbClients, components: enGbComponents, docbuilder: enGbDocbuilder },
  'en-US': { sessions: enUsSessions, clients: enUsClients, components: enUsComponents, docbuilder: enUsDocbuilder },
  es: { sessions: esSessions, clients: esClients, components: esComponents, docbuilder: esDocbuilder },
  pt: { sessions: ptSessions, clients: ptClients, components: ptComponents, docbuilder: ptDocbuilder },
  fr: { sessions: frSessions, clients: frClients, components: frComponents, docbuilder: frDocbuilder },
  de: { sessions: deSessions, clients: deClients, components: deComponents, docbuilder: deDocbuilder },
  it: { sessions: itSessions, clients: itClients, components: itComponents, docbuilder: itDocbuilder },
  ja: { sessions: jaSessions, clients: jaClients, components: jaComponents, docbuilder: jaDocbuilder },
  ko: { sessions: koSessions, clients: koClients, components: koComponents, docbuilder: koDocbuilder },
  tr: { sessions: trSessions, clients: trClients, components: trComponents, docbuilder: trDocbuilder },
  da: { sessions: daSessions, clients: daClients, components: daComponents, docbuilder: daDocbuilder },
  hi: { sessions: hiSessions, clients: hiClients, components: hiComponents, docbuilder: hiDocbuilder },
  ar: { sessions: arSessions, clients: arClients, components: arComponents, docbuilder: arDocbuilder },
  'zh-HK': { sessions: zhHkSessions, clients: zhHkClients, components: zhHkComponents, docbuilder: zhHkDocbuilder },
  'zh-CN': { sessions: zhCnSessions, clients: zhCnClients, components: zhCnComponents, docbuilder: zhCnDocbuilder },
};
